import React from "react";
import Main from "../common/Main";
import SectionContainer from "../common/SectionContainer";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

export default function Obrigado() {
  return (
    <Main>
      <Navbar backgroundColor="#e3e3e3" display="none" />
      <SectionContainer>
        <h2 style={{ padding: "60px 0", height: "calc(100vh - 290px)" }}>
          Formulário enviado com sucesso. Obrigado por entrar em contato, em
          breve retornaremos via email.
        </h2>
      </SectionContainer>
      <Footer />
    </Main>
  );
}
